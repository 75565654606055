<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column12">
					<div v-parse-links v-html="defaults[locale].website.footerContent" />
				</div>
				<div class="columns column12 align-center">
					<p class="address">
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }},</span>&nbsp;
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span
						>&nbsp; <span>{{ defaults[locale].website.country }}</span
						>&nbsp;
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span> </a
						>&nbsp;
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { locale } = useI18n();

const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
.footers {
	color: $footer-color;
	text-transform: uppercase;
	letter-spacing: 1px;

	a,
	span {
		color: $footer-color;
		text-decoration: none;
	}
}

.main-footer {
	p,
	:deep(p) {
		font-size: 17px;
		font-weight: 400;
		text-align: center;
		margin: 0;
	}

	a:hover {
		text-decoration: underline;
	}
}

@media (max-width: 1080px) {
	footer .columns {
		text-align: center;
	}
}

@media (max-width: 800px) {
	.main-footer {
		padding: 0 50px;

		p,
		:deep(p) {
			font-size: 14px;
		}
	}
}

@media (max-width: 580px) {
	.main-footer {
		p,
		:deep(p) {
			font-size: 12px;
		}
	}
}
</style>

<template>
	<div>
		<nav class="main-nav delay fix-on-scroll" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<div class="nav-content">
							<ul>
								<li v-for="item in topmenu[locale]" :key="item.filename" class="nav-item">
									<span @click="hideMobileNav">
										<nuxt-link :to="item.filename">
											{{ item.header }}
										</nuxt-link>
									</span>
									<submenu
										v-if="item.submenu.length"
										:subitems="item.submenu"
										@hide-mobile-nav="hideMobileNav"
									/>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

if (!defaults.value) {
	await fetchDefaults();
}

const showMobileNav = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const hideMobileNav = () => {
	showMobileNav.value = false;
};
</script>

<style lang="scss" scoped>
@media (min-width: 1201px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.mobile-only {
	display: none !important;
}

.main-nav {
	z-index: 1000;

	ul li {
		display: inline-block;
		padding: 5px 0;
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	padding: 3px 0;
	z-index: 9;
	position: relative;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			padding: 15px 25px;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.icon {
			display: none;
			margin-right: 5px;
			font-size: 13px;
		}
	}
}

.menu-button {
	cursor: pointer;
}

.nav-content {
	margin: auto;

	ul {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
	}

	a {
		color: $cta-color;
		font-size: 28px;
		text-decoration: none;
		text-transform: uppercase;
		text-align: center;
		margin: 0 15px;
		letter-spacing: 1px;
		font-weight: 400;
		transition: all 0.3s ease-in-out;
		border-bottom: 2px solid rgba(0 0 0 / 0%);

		&.active,
		&:hover {
			color: $cta-color;
			border-color: $cta-color;
		}
	}

	.menu-close {
		color: #fff;
		display: none;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 15px;
		width: auto;
		right: 20px;
		padding: 0;
		margin: 0;
	}

	.lang-nav {
		margin: 25px auto;
		padding: 15px 0 0;
		border-top: 1px solid #6d6d6d;
		display: inline-block;
		max-width: 400px;

		.language {
			margin: 20px 0;
		}

		a {
			font-size: 15px;
			color: #fff;

			&.router-link-exact-active,
			&:hover {
				color: $cta-color;
			}
		}

		img {
			max-width: 16px;
			margin: -2px 5px 0 0;
			vertical-align: middle;
		}
	}
}

@media (max-width: 800px) {
	.nav-content {
		margin: 0 auto 20px;

		a {
			font-size: 21px;
		}
	}
}

@media (max-width: 580px) {
	.nav-content {
		margin: 0 auto 20px;

		a {
			font-size: 20px;
			margin: 0 10px;
		}
	}
}
</style>
